<template>
  <div class="column content-box">
    <woot-modal-header
      v-if="!isCallingType"
      :header-title="$t('CAMPAIGN.ADD.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.DESC')"
    />
    <woot-modal-header
      v-if="isCallingType"
      header-title="Outbound Calling Campaign"
      header-content="Create outbound calling campaign to call the customers in order to increase your sales"
    />
    <form v-if="!isCallingType" class="row" @submit.prevent="addCampaign">
      <div class="medium-12 columns">
        <woot-input
          v-if="!isEmailType"
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />

        <woot-input
          v-if="isEmailType"
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.SUBJECT')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.SUBJECT_ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.SUBJECT_PLACEHOLDER')"
          @blur="$v.title.$touch"
        />

        <label v-if="isOngoingType" class="editor-wrap">
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
          <resizable-text-area
            v-model="message"
            class="message-editor"
            :class="{ editor_warning: $v.message.$error }"
            spellcheck="true"
            :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
            :min-height="4"
            @blur="$v.message.$touch"
          />
          <span v-if="$v.message.$error" class="editor-warning__message">
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
          </span>
        </label>

        <label v-else-if="isEmailType" :class="{ error: $v.message.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
          <quill-editor
            v-model="message"
            :options="editorOptions"
            @ready="onEditorReady"
            @blur="onEditorBlur"
            class="quill-editor"
          />
          <span v-if="$v.message.$error" class="message">
        {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
      </span>
        </label>

        <label v-else :class="{ error: $v.message.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
          <textarea
            v-model="message"
            rows="5"
            type="text"
            :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
            @blur="$v.message.$touch"
          />
          <span v-if="$v.message.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
          </span>
        </label>

        <label :class="{ error: $v.selectedInbox.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
          <select v-model="selectedInbox" @change="onChangeInbox($event)">
            <option v-for="item in inboxes" :key="item.name" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.selectedInbox.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
          </span>
        </label>

        <label
          v-if="isOnOffType || isEmailType"
          :class="{ error: $v.selectedAudience.$error }"
        >
          {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
          <multiselect
            v-model="selectedAudience"
            :options="audienceList"
            track-by="id"
            label="title"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @blur="$v.selectedAudience.$touch"
            @select="$v.selectedAudience.$touch"
          />
          <span v-if="$v.selectedAudience.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
          </span>
        </label>

        <label
          v-if="isOngoingType"
          :class="{ error: $v.selectedSender.$error }"
        >
          {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.LABEL') }}
          <select v-model="selectedSender">
            <option
              v-for="sender in sendersAndBotList"
              :key="sender.name"
              :value="sender.id"
            >
              {{ sender.name }}
            </option>
          </select>
          <span v-if="$v.selectedSender.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.ERROR') }}
          </span>
        </label>

        <span v-if = "isEmailType">
                      <input
                        v-model="selectedType"
                        type="radio"
                        value="ongoing"
                      />
                      <label for="ongoing">
                        Ongoing Campaign
                      </label>
                        </span>
        <span v-if="isEmailType">
                      <input
                        v-model="selectedType"
                        type="radio"
                        value="one_off"
                      />
                      <label for="ongoing" >
                        One Off Campaign
                      </label>
                        </span>

        <label v-if = "isOnOffType || selectedType == 'one_off'">
          {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-time-picker
            :value="scheduledAt"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
            @change="onChange"
          />
        </label>

        <woot-input
          v-if="isOngoingType"
          v-model="endPoint"
          :label="$t('CAMPAIGN.ADD.FORM.END_POINT.LABEL')"
          type="text"
          :class="{ error: $v.endPoint.$error }"
          :error="
            $v.endPoint.$error ? $t('CAMPAIGN.ADD.FORM.END_POINT.ERROR') : ''
          "
          :placeholder="$t('CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER')"
          @blur="$v.endPoint.$touch"
        />
        <woot-input
          v-if="isOngoingType"
          v-model="timeOnPage"
          :label="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.LABEL')"
          type="text"
          :class="{ error: $v.timeOnPage.$error }"
          :error="
            $v.timeOnPage.$error
              ? $t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR')
              : ''
          "
          :placeholder="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER')"
          @blur="$v.timeOnPage.$touch"
        />
        <label v-if="isOngoingType">
          <input
            v-model="enabled"
            type="checkbox"
            value="enabled"
            name="enabled"
          />
          {{ $t('CAMPAIGN.ADD.FORM.ENABLED') }}
        </label>
        <label v-if="isOngoingType">
          <input
            v-model="triggerOnlyDuringBusinessHours"
            type="checkbox"
            value="triggerOnlyDuringBusinessHours"
            name="triggerOnlyDuringBusinessHours"
          />
          {{ $t('CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS') }}
        </label>
      </div>

      <div class="modal-footer">
        <woot-button :is-loading="uiFlags.isCreating">
          {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>

    <form v-if="isCallingType" class="row" @submit.prevent="addCampaign">
      <div class="medium-12 columns">
        <woot-input
          v-model="title"
          label="Title"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />
        <span>
  <input
    v-model="callingNumberType"
    type="radio"
    value="inboxes"
  />
  <label for="ongoing">
    {{$t('CAMPAIGN.ADD.CALLING.INBOXES')}}
  </label>
</span>

        <span>
  <input
    v-model="callingNumberType"
    type="radio"
    value="auto_pool"
  />
  <label >
    {{$t('CAMPAIGN.ADD.CALLING.AUTO_POOL')}}
  </label>
</span>

        <label v-if="callingNumberType == 'inboxes'" :class="{ error: $v.callingInbox.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
          <select v-model="callingInbox" >
            <option v-for="item in inboxes" :key="item.name" :value="item">
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.callingInbox.$error" class="message">
    {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
           </span>
        </label>

<!--        <label  :class="{ error: $v.selectedAreaCode.$error }" v-else-if="callingNumberType === 'caller_id'">-->
<!--          Area Code-->
<!--          <div v-if="availableAreaCodes.length > 0">-->
<!--            <select v-model="selectedAreaCode" @change="onChangeAreaCode">-->
<!--              <option v-for="code in availableAreaCodes" :key="code" :value="code">-->
<!--                {{ code }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <span>No available area code</span>-->
<!--          </div>-->
<!--        </label>-->


            <woot-input
              v-if="callingNumberType == 'auto_pool'"
              v-model="forwardToPhone"
              label="Forward To Phone Number"
              type="text"
              :class="{ error: !validatePhone(forwardToPhone) || $v.forwardToPhone.$error }"
              :error="$v.forwardToPhone.$error ? 'Provide Forward to Phone number': ''"
              placeholder="Forward To Phone number +12345678910"
              @blur="$v.forwardToPhone.$touch"
              @input="validatePhone(forwardToPhone)"
            />



        <label :class="{ error: $v.selectedAiAgent.$error }">
          {{$t('CAMPAIGN.ADD.CALLING.AI_AGENT')}}
          <div>
            <select v-model="selectedAiAgent" @change="onChangeAiAgent">
              <option v-for="agent in availableAiAgents" :key="agent.id" :value="agent.id">
                {{ agent.name }}
              </option>
            </select>
          </div>
        </label>

        <label >
          {{$t('CAMPAIGN.ADD.CALLING.TIMEZONE')}}
          <select v-model="selectedTimezone">
            <option v-for="timezone in timezones" :key="timezone" :value="timezone">
              {{ timezone }}
            </option>
          </select>
        </label>

<!--        <div class="date-picker-container">-->
<!--          <div :class="{ error: $v.startDate.$error }" class="date-picker-item" >-->
<!--            <label for="startDate">{{$t('CAMPAIGN.ADD.CALLING.START_DATE')}}:</label>-->
<!--            <woot-date-time-picker-->
<!--              :value="startDate"-->
<!--              confirm-text="Confirm"-->
<!--              placeholder="Select Start Date"-->
<!--              @change="setStartDate"-->
<!--              :disabled="false"-->
<!--            />-->
<!--          </div>-->

<!--          <div :class="{ error: $v.endDate.$error }" class="date-picker-item" >-->
<!--            <label for="endDate">{{$t('CAMPAIGN.ADD.CALLING.END_DATE')}}:</label>-->
<!--            <woot-date-time-picker-->
<!--              :value="endDate"-->
<!--              confirm-text="Confirm"-->
<!--              placeholder="Select End Date"-->
<!--              @change="setEndDate"-->
<!--              :disabled=" !startDate"-->
<!--              :min-date="startDate"-->
<!--              :max-date="maxEndDate"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

        <div class="date-picker-container">
          <div :class="{ error: $v.startDate.$error }" class="date-picker-item" >
            <label for="startDate">{{$t('CAMPAIGN.ADD.CALLING.START_DATE')}}:</label>
            <date-picker-only
              :value="startDate"
              confirm-text="Confirm"
              placeholder="Select Start Date"
              @change="setStartDate"
              :disabled="false"
            />
          </div>

          <div :class="{ error: $v.endDate.$error }" class="date-picker-item" >
            <label for="endDate">{{$t('CAMPAIGN.ADD.CALLING.END_DATE')}}:</label>
            <date-picker-only
              :value="endDate"
              confirm-text="Confirm"
              placeholder="Select End Date"
              @change="setEndDate"
              :disabled=" !startDate"
              :min-date="startDate"
              :max-date="maxEndDate"
            />
          </div>
        </div>


           <div class="date-picker-container">
             <div :class="{ error: $v.startTime.$error }" class="date-picker-item">
               <label for="startTime">{{$t('CAMPAIGN.ADD.CALLING.START_TIME')}}:</label>
               <div class="date-picker">
                 <date-picker
                   v-model="startTime"
                   type="time"
                   :confirm="true"
                   :clearable="false"
                   :editable="false"
                   :placeholder="'Select Start Time'"
                   :value="startTime"
                 />
               </div>

             </div>

             <div :class="{ error: $v.endTime.$error }" class="date-picker-item">
               <label for="endTime">{{$t('CAMPAIGN.ADD.CALLING.END_TIME')}}:</label>
               <div class="date-picker">
                 <date-picker
                   v-model="endTime"
                   type="time"
                   :confirm="true"
                   :clearable="false"
                   :editable="false"
                   :placeholder="'Select End Time'"
                   :value="endTime"
                 />
               </div>
             </div>
          </div>

<span>
  <input
    v-model="audianceType"
    type="radio"

    value="select_label"
  />
  <label>
    {{$t('CAMPAIGN.ADD.CALLING.SELECT_TAG')}}
  </label>
</span>



        <span>
  <input
    v-model="audianceType"
    type="radio"
    value="select_CSV"
  />
  <label >
    {{$t('CAMPAIGN.ADD.CALLING.UPLOAD_CSV')}}
  </label>
</span>
        <span>
          <label v-if="audianceType=='select_label'"
          >
          {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
          <multiselect
            v-model="selectedAudience"
            :options="audienceList"
            track-by="id"
            label="title"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          />
        </label>
        </span>
        <span>
          <span v-if="audianceType=='select_label'" class="add-ids-button medium-12 columns" @click="addLabelPhoneNumbers"> {{$t('CAMPAIGN.ADD.CALLING.APPLY')}}</span>
        </span>



        <div v-if="audianceType=='select_CSV'">
          <div >
            <div class="medium-12 columns">
              <label>
                <span>{{ $t('IMPORT_CONTACTS.FORM.LABEL') }}</span>
                <input
                  id="file"
                  ref="file"
                  type="file"
                  accept="text/csv"
                  @change="handleFileUpload"
                />
              </label>
            </div>
            <div class="modal-footer">
              <div class="medium-12 columns">
               <span
                 @click="uploadFile"
                 class="import-button-style"
               >
  {{ $t('IMPORT_CONTACTS.FORM.SUBMIT') }}
</span>
              </div>
            </div>
          </div>
<!--          <span class="add-ids-button medium-12 columns" @click="uploadCSV">Upload a CSV</span>-->
        </div>

        <span v-if="leadsCount<1" :class="{error: leadsRequired}" class="editor-warning__message">
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
          </span>

        <div v-if="leadsCount >= 1" style="display: flex; justify-content: space-between; gap: 20px;">
          <div>{{$t('CAMPAIGN.ADD.CALLING.COUNT')}}: {{ leadsCount }}</div>
          <div v-if="callingNumberType === 'auto_pool'">{{$t('CAMPAIGN.ADD.CALLING.AREA_CODES')}}: {{ areaCodesCount }}</div>
          <div>{{$t('CAMPAIGN.ADD.CALLING.TYPE')}}: {{ audienceFrom }}</div>
        </div>


        <label :class="{ error: $v.callLength.$error }"> {{$t('CAMPAIGN.ADD.CALLING.LENGTH')}}
          <select v-model="callLength" id="dayRange">
            <option v-for="n in 60" :key="n" :value="n">{{ n }} {{$t('CAMPAIGN.ADD.CALLING.MINUTES')}}</option>
          </select>
        </label>

      </div>

      <div class="modal-footer">
        <span v-if="!finilized" class="add-ids-button medium-12 columns" @click="finilizeCampaign"> {{$t('CAMPAIGN.ADD.CALLING.FINALIZE')}}</span>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, url, minLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import ResizableTextArea from 'shared/components/ResizableTextArea';
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import moment from 'moment-timezone';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import DatePicker from 'vue2-datepicker';
import DatePickerOnly from '../../../../components/ui/DatePickerOnly.vue';
import { validatePhoneForE164 } from '../../../../../shared/helpers/Validators';

export default {
  components: {
    DatePicker,
    WootDateTimePicker,
    ResizableTextArea,
    DatePickerOnly,
    quillEditor,
  },

  mixins: [alertMixin, campaignMixin],
  data() {
    return {
      callLength:'',
      audienceFrom:'lable',
      callLengthrequired:true,
      forwardToPhone: '',
      campaignObj:{},
      callingInbox:'',
      callInboxRequired:true,
      leadsArray:[],
      leadsRequired:false,
      areaCodesCount:'',
      leadsCount:'',
      leadsCountRequired:true,
      selectedType: 'ongoing',
      callingNumberType: 'inboxes',
      audianceType:'select_label',
      availablePhoneNumbers: [],
      availableAiAgents: [
      ],
      availableAreaCodes: [],
      showAddIdsModal: false,
      selectedTimeZone:'',
      timeZoneRequired:true,
      startTime: null,
      endTime: null,
      campaignPayload:'',
      timezones: [],
      finilized:false,
      automatic_pool:false,
      startDate: '',
      endDate: '',
      validCampaign:'',
      selectedAiAgent:'',
      selectedAreaCode:'',
      selectedPhone:'',
      file: '',
      phone:'',
      title: '',
      message: '',
      selectedSender: 0,
      selectedInbox: null,
      endPoint: '',
      timeOnPage: 10,
      show: true,
      enabled: true,
      triggerOnlyDuringBusinessHours: false,
      scheduledAt: null,
      selectedAudience: [],
      senderList: [],
      editorOptions: {
        placeholder: this.$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER'),
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }, 'link' ],
            [{ 'align': [] }],
            ['clean'],
          ],
          clipboard: {
            matchVisual: false,
          },
        },
        theme: 'snow',
        bounds: '.quill-editor',
      }
    };
  },
  created() {
    this.timezones = moment.tz.names();
    this.setInitialTimezone();
    this.fetchAiAgents();
  },
  watch: {
    callingNumberType() {
      this.resetDates();
    }
  },

  validations() {
    const commonValidations = {
      title: {
        required,
      },
      message: {
        required,
      },
      selectedInbox: {
        required,
      },
    };
    if (this.isOngoingType) {
      return {
        ...commonValidations,
        selectedSender: {
          required,
        },
        endPoint: {
          required,
          minLength: minLength(7),
          url,
        },
        timeOnPage: {
          required,
        },
      };
    }
    if(this.isCallingType)
    {
      let inboxValidations = {};
      let autoPoolValidations = {};

      if (this.callingNumberType == 'inboxes'){
        inboxValidations = {
          callingInbox: {
            required
          }
        };
      }
      if (this.callingNumberType == 'auto_pool'){
        autoPoolValidations = {
          forwardToPhone:{
            required,
          }
        };
      }

      return {
        ...autoPoolValidations,
        ...inboxValidations,
         title: {
        required,
      },
        startTime: {
          required,
        },
        endTime: {
          required,
        },
        endDate: {
          required,
        },
        startDate: {
          required,
        },
        callLength:{
          required,
        },
        selectedAiAgent: {
          required,
        },
      };
    }
    return {
      ...commonValidations,
      selectedAudience: {
        isEmpty() {
          return !!this.selectedAudience.length;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      audienceList: 'labels/getLabels',
      accountId: 'getCurrentAccountId',
      appIntegrations: 'integrations/getAppIntegrations',
      getAccount: 'accounts/getAccount',
    }),
    balance() {
      let account = this.getAccount(this.accountId);
      let balance = Number(account.credit_balance);
      return isNaN(balance) ? 0 : Math.floor(balance);
    },
    maxEndDate() {
      if (this.callingNumberType === 'auto_pool' && this.startDate) {
        return moment(this.startDate).add(14, 'days').toDate();
      }
      return null;
    },
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      else if(this.isEmailType) {
        let allinboxes = this.$store.getters['inboxes/getEmailInboxes'];
        const getSmtpEnabledValues = (allinboxes) => {
          if (Array.isArray(allinboxes) && allinboxes.length > 0) {
            return allinboxes.filter(inbox => inbox.smtp_enabled === true);
          } else {
            return [];
          }
        };
        const smtpEnabledInboxes = getSmtpEnabledValues(allinboxes);
        console.log(smtpEnabledInboxes);
        return smtpEnabledInboxes;
      }
      // return this.$store.getters['inboxes/getTwilioSMSInboxes'];
      return this.$store.getters['inboxes/getSignalWireSMSInboxes'];
    },
    sendersAndBotList() {
      return [
        {
          id: 0,
          name: 'Bot',
        },
        ...this.senderList,
      ];
    },
  },
  methods: {
    resetDates(){
      this.callingInbox='';
      this.selectedPhone='';
      this.startDate='';
        this.endDate='';
    },
    validatePhone(phone) {
      if (phone && phone.length > 0) {
        return validatePhoneForE164(phone);
      } else {
        return true;
      }
    },
    // async fetchAreaCodes() {
    //   try {
    //     const response = await axios.get('https://agentapi.conversate.us/v1/campaign/list_area_codes', {
    //       headers: {
    //         'Authentication': 'ec90c54bb8f24c7e9eekjahgs7bb8762jdf51'
    //       }
    //     });
    //
    //     this.availableAreaCodes = Object.values(response.data.area_codes).flat();
    //   } catch (error) {
    //     console.error('Error fetching area codes:', error);
    //   }
    // },
    async fetchAiAgents() {
      try {
        const response = await axios.get(`https://agentapi.conversate.us/v1/campaign/list_agents/${this.accountId}`, {
          headers: {
            'Authentication': 'ec90c54bb8f24c7e9eekjahgs7bb8762jdf51'
          }
        });

        this.availableAiAgents = response.data.agents;
      } catch (error) {
        console.error('Error fetching area codes:', error);
      }
    },
    async finilizeCampaign(){
      this.$v.$touch();
      if (this.$v.$invalid || this.validatePhone(this.forwardToPhone) == false) {
        return;
      }

      if (this.leadsCount<=0)
      {
        this.leadsRequired = true
        return;
      }
      if (this.leadsCount>0)
      {
        this.leadsRequired = false
      }
      if(this.callingNumberType === 'auto_pool'){
        this.automatic_pool = true
      }
      if(this.callingNumberType == 'inboxes'){
        this.automatic_pool = false
      }

      const formattedStartDate = moment(this.startDate).format('MM/DD/YYYY HH:mm:ss');
      const formattedStartTime = moment(this.startTime).format('HH:mm:ss');
      const formattedEndDate = moment(this.endDate).format('MM/DD/YYYY HH:mm:ss');
      const formattedEndTime = moment(this.endTime).format('HH:mm:ss');

      this.campaignPayload = {
        account_id: this.accountId.toString(),
        account_balance_credits: this.balance,
        campaign_name: this.title,
        automatic_pool: this.automatic_pool,
        inbox_phone_number: this.callingInbox.phone_number || '',
        call_forwarding_phone_number: this.forwardToPhone,
        ai_agent_id: this.selectedAiAgent.toString(),
        leads: this.leadsArray.map(lead => ({
          first_name: lead.first_name,
          last_name: lead.last_name,
          email: lead.email ? lead.email : '',
          phone_number: lead.phone
        })),
        timezone: this.selectedTimezone,
        campaign_duration: {
          start_date: formattedStartDate,
          end_date: formattedEndDate
        },
        outbout_calling_timeframe: {
          start_time: formattedStartTime,
          end_time: formattedEndTime
        },
        estimated_call_duration: this.callLength
      };
      let response={};
      try {
         response = await axios.post('https://agentapi.conversate.us/v1/campaign/validate_campaign', this.campaignPayload ,{
          headers: {
            'Authentication': 'ec90c54bb8f24c7e9eekjahgs7bb8762jdf51'
          },
        });
        if(response.status == '200')
        {
          this.campaignObj= this.campaignPayload
          this.campaignPayload.result = response.data.ready_to_start;
          this.campaignPayload.audianceType=this.audianceType
          this.campaignPayload.message= response.data.message;
          this.campaignPayload.estimated_cost = response.data.estimated_cost;
          this.campaignPayload.leads_count = this.leadsCount;
          this.campaignPayload.areaCodesCount = this.areaCodesCount
          if(this.automatic_pool==false)
          {
            this.campaignPayload.inbox_id = this.callingInbox.id
          }
          this.onClose()
          this.$emit('open-add-ids-modal', this.campaignPayload, this.campaignObj);
        }
      } catch (error) {
        this.showAlert(`Campaign Can't validated ${error}`);
        this.onClose()
      }
    },
    setInitialTimezone() {
      this.selectedTimezone = "America/Los_Angeles";
      if (this.timezones.includes(this.selectedTimezone)) {
        this.selectedTimezone = this.selectedTimezone;
      }
    },
    setStartDate(date) {
      this.startDate = date;
    },
    setEndDate(date) {
      this.endDate = date;
    },
    onChangeAiAgent(){
      console.log("AiAgent");
    },
    // async onChangeAreaCode() {
    //   try {
    //     const payload = {
    //       account_id: '1',
    //       area_code: this.selectedAreaCode
    //     };
    //
    //     const response = await axios.post('https://agentapi.conversate.us/v1/campaign/list_phone_numbers', payload, {
    //       headers: {
    //         'Authentication': 'ec90c54bb8f24c7e9eekjahgs7bb8762jdf51'
    //       }
    //     });
    //     this.availablePhoneNumbers=response.data.phone_numbers;
    //     console.log('POST response:', response.data);
    //   } catch (error) {
    //     console.error('Error in POST request:', error);
    //   }
    //   },
    async addLabelPhoneNumbers() {
  try {
    if (!this.selectedAudience) return;
    const response = await this.$store.dispatch('contacts/applyLabels', this.selectedAudience);
    console.log("response", response.data);
    this.leadsArray = response.data.unique_records;
    this.areaCodesCount = response.data.unique_area_codes_count;
    this.leadsCount = response.data.unique_records_count;
    this.audienceFrom = 'labels'
    if(this.leadsCount>0)
    {
      this.showAlert(this.$t('IMPORT_CONTACTS.SUCCESS_MESSAGE'));
    }else{
      this.showAlert("No Valid Contacts Found");
    }
  } catch (error) {
    this.showAlert(
      error.message || this.$t('IMPORT_CONTACTS.ERROR_MESSAGE')
    );
  }
},
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    async uploadFile() {
      try {
        if (!this.file) return;
        const response = await this.$store.dispatch('contacts/importLead', this.file);
        console.log("response", response.data);
        this.leadsArray = response.data.unique_records;
        this.areaCodesCount = response.data.unique_area_codes_count;
        this.leadsCount = response.data.unique_records_count;
        this.audienceFrom = 'Uploaded'
        this.showAlert(this.$t('IMPORT_CONTACTS.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('IMPORT_CONTACTS.ERROR_MESSAGE')
        );
      }
    },
    onEditorReady(editor) {
      editor.root.addEventListener('click', (event) => {
        if (event.target && event.target.tagName === 'LI') {
          event.stopPropagation();
        }
      });
      editor.container.classList.add('quill-editor-container');
    },
    onEditorBlur(editor) {
      this.$v.message.$touch()
    },
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    async onChangeInbox() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.selectedInbox,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.senderList = inboxMembers;
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    getCampaignDetails() {
      let campaignDetails = null;
      if (this.isOngoingType) {
        campaignDetails = {
          title: this.title,
          message: this.message,
          inbox_id: this.selectedInbox,
          sender_id: this.selectedSender || null,
          enabled: this.enabled,
          trigger_only_during_business_hours:
            this.triggerOnlyDuringBusinessHours,
          trigger_rules: {
            url: this.endPoint,
            time_on_page: this.timeOnPage,
          },
        };
      }
      else if(this.isEmailType) {
          const audience = this.selectedAudience.map(item => {
            return {
              id: item.id,
              type: 'Label',
            };
          });
          campaignDetails = {
            title: this.title,
            selected_type: this.selectedType,
            message: this.message,
            inbox_id: this.selectedInbox,
            scheduled_at: this.scheduledAt,
            audience,
          };
      }
      else {
        const audience = this.selectedAudience.map(item => {
          return {
            id: item.id,
            type: 'Label',
          };
        });
        campaignDetails = {
          title: this.title,
          message: this.message,
          inbox_id: this.selectedInbox,
          scheduled_at: this.scheduledAt,
          audience,
        };
      }
      return campaignDetails;
    },
    async addCampaign() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const campaignDetails = this.getCampaignDetails();
        await this.$store.dispatch('campaigns/create', campaignDetails);
        this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}

.add-ids-button {
  background-color: #1f93ff;
  color: white;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.import-button-style {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  padding: 12px 16px;
  background-color: #1f93ff;
  color: white;
  text-align: center;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.import-button-style:hover {
  background-color: #0056b3;
}

.date-picker-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}


.date-picker-item {
  flex: 1;
  display: flex;
  flex-direction: column;
}


.date-picker-item label {
  margin-bottom: 0.5rem;
}

.date-picker-item input {
  width: 100%;
}

.add-ids-button:hover {
  background-color: blue;
}

.quill-editor {
  margin-bottom: 1rem;

  ::v-deep .quill-editor-container {
    display: flex;
    flex-direction: column;
    height: 300px;

    .ql-toolbar {
      flex-shrink: 0;
    }

    .ql-container {
      flex-grow: 1;
      min-height: 150px;
      max-height: calc(300px - 42px);
      overflow-y: auto;
    }
  }
}



</style>
